<template>
  <div>
    <b-modal
      id="modal-center"
      v-model="showModal"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      centered
      :title="title"
      @hide="onCancel"
    >
      <b-overlay
        :show="isLoading"
        rounded="sm"
        spinner-type="grow"
        spinner-small
        variant="white"
        opacity="1"
        class="m-4"
      >
        <div class="row">
          <div class="col-md-12">
            <label class="mb-0">License</label>
            <h5><span class="badge badge-pill badge-primary">{{ dataSend.count }} {{ dataSend.periode === '0' ? 'month' : 'year' }}</span></h5>

            <div v-if="$route.params.id === '0'">
              <label>Vessel</label>
              <ValidationObserver ref="form">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Vessel"
                  rules="required"
                >
                  <b-form-select
                    v-model="vessel"
                    :options="dropdownVessel"
                    :state=" errors[0] ? false : null"
                    @change="loadDetailVessel"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </ValidationObserver>
            </div>

            <hr>
            <div v-if="dataSend.vessel">
              <label>Vessel Name</label>
              <p class="text-muted">
                {{ dataSend.vessel.name }}
              </p>
              <label>Vessel Type</label>
              <p class="text-muted">
                {{ dataSend.vessel.vessel_type.name }}
              </p>
              <div v-if="dataSend.vessel.vessel_type.id !== 6">
                <label>Load Capacity</label>
                <p class="text-muted">
                  {{ $parent.thausandSparator(dataSend.vessel.load_capacity) }} MT
                </p>
              </div>
              <div v-else>
                <label>Horsepower</label>
                <p class="text-muted">
                  {{ $parent.thausandSparator(dataSend.vessel.engine.horsepower) }} HP
                </p>
              </div>
            </div>
          </div>
        </div>

        <b-button
          class="mt-3 mx-0"
          variant="primary"
          block
          @click="onSubmit"
        >
          Submit
        </b-button>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import { SwalConfirm, SwalWarning } from "../../plugins/sweetalert2";
import API from '../../plugins/axios'

export default {
  name: "LicenseAction",
  props: {
    show: {
      type: Boolean,
      default: true
    },
    data: {
      type: Object,
      default: Object
    }
  },
  data () {
    return {
      showModal: false,
      isLoading: false,
      title: 'Submission License',

      dropdownVessel: [],
      vessel: null,

      dataSend: {
        vessel: {
          name: '',
          vessel_type: '',
          load_capacity: ''
        },
        start: '',
        count: '',
        periode: ''
      }
    }
  },
  watch: {
    show () {
      this.showModal = this.show
      this.dataSend = this.data
      if (this.$route.params.id === '0' && this.show) this.loadDropdownVessel()
    }
  },
  methods: {
    onCancel () {
      this.$emit('toggle')
    },

    onSubmit () {
      if (this.$route.params.id === '0') {
        this.$refs.form.validate().then(success => {
          if (!success) {
            SwalWarning ('Mohon isi field (wajib) yang belum di isi.')
          } else {
            this.submitHandler(this.vessel)
          }
        })
      } else {
        this.submitHandler(this.$route.params.id)
      }
    },

    submitHandler (id) {
      SwalConfirm ('Anda yakin ingin membeli license ?', () => {
        const payload = {
          vessel: id,
          end_date: this.dataSend.count,
          filter: this.dataSend.periode
        }
        this.$store.dispatch('moduleBuyLicense/CREATE_LICENSE', payload)
      })
    },

    async loadDetailVessel () {
      await this.$store.dispatch('moduleBuyLicense/LOAD_DETAIL_VESSEL', this.vessel)
      this.dataSend.vessel = this.$store.getters['moduleBuyLicense/getDataVessel']

    },

    async loadDropdownVessel () {
      const vm = this
      vm.vessel = ''
      vm.dropdownVessel = []
      await API.get("master/vessel/dropdown")
        .then((response) => {
          const data = response.data.data
          data.map(el => {
            vm.dropdownVessel.push({
              text: el.name,
              value: el.id
            })
          })
        })
      await API.get("master/vessel/tugboat/dropdown")
        .then((response) => {
          const data = response.data.data
          data.map(el => {
            vm.dropdownVessel.push({
              text: el.name,
              value: el.id
            })
          })
        })
    }
  }
}
</script>

<style scoped>
</style>
