<template>
  <div class="fab-group">
    <a :href="email">
      <div class="fab-item bg-warning text-white">
        <i class="fas fa-envelope fa-2x" />
      </div>
    </a>
    <transition name="fade">
      <div
        v-show="offerTips"
        class="more-offer"
      >
        <i class="fas fa-smile-wink fa-2x text-warning" />
        <div class="mx-2">
          Let's talk to more offer.
        </div>
        <i
          class="fas fa-times fa-sm text-muted c-pointer"
          @click="offerTips = false"
        />
      </div>
    </transition>
    <a
      :href="whatsapp"
      @mouseover="offerTips = true"
    >
      <div class="fab-item bg-success text-white">
        <i class="fab fa-whatsapp fa-2x" />
      </div>
    </a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      offerTips: false,
      email:'mailto:sales@zonasea.com?cc=muara@zonasea.com&subject=Tertarik Dengan Aplikasi Zonasea&body=Halo,%0A%0ASaya tertarik menggunakan aplikasi Zonasea. Tolong hubungi saya kembali ya.%0A%0ATerimakasih.',
      whatsapp: 'https://api.whatsapp.com/send?phone=+6288226622240&text=Halo, saya tertarik untuk menggunakan aplikasi Zonasea, tolong hubungi saya kembali ya. Terima kasih'
    }
  },
  mounted () {
    setTimeout(() => this.offerTips = true, 4000)
  },
}
</script>

<style scoped>
  .fab-group {
    position: fixed;
    bottom: 25px;
    right: 25px;
    width: auto;
    max-width: 150px;
    height: 50px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .fab-group a {
    color: white;
    text-decoration: none;
  }

  .fab-group .fab-item {
    margin: 5px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-shadow: 1px 2px 2px black;
  }

  .fab-group .fab-item:hover {
    cursor: pointer;
    margin: 5px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-shadow: 0 0 10px 2px black;
  }

  .more-offer {
    z-index: 99999999;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 75px;
    left: -15px;
    padding: 10px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 2px 10px #888;
    transition: opacity 0.1s ease-in-out;
  }

  .more-offer::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 75%;
    border-width: 5px;
    border-style: solid;
    border-color: white transparent transparent transparent;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter,
  .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

</style>