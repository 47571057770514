<template>
  <div>
    <div class="container pt-5">
      <div
        v-if="$route.params.id != 0"
        class="px-3"
      >
        <div class="pricing-header mx-auto text-center">
          <h2 class="mt-5">
            Vessel Information
          </h2>
          <p class="text-muted">
            This is the ship you choose to purchase the license.
          </p>
        </div>
        <div class="detail card mb-4 rounded shadow-sm">
          <div
            v-if="isLoading"
            class="card-body p-5"
          >
            <b-skeleton
              animation="wave"
              width="85%"
            />
            <b-skeleton
              animation="wave"
              width="85%"
            />
            <b-skeleton
              animation="wave"
              width="85%"
            />
          </div>
          <div
            v-else
            class="card-body p-5"
          >
            <div class="row mb-3">
              <div class="col-md-4">
                Vessel Name
              </div>
              <div class="col-md-8">
                : {{ getDataVessel.name }}
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-4">
                Vessel Type
              </div>
              <div class="col-md-8">
                : {{ getDataVessel.vessel_type.name }}
              </div>
            </div>
            <div
              v-if="getDataVessel.vessel_type.id !== 6"
              class="row"
            >
              <div class="col-md-4">
                Load Capacity
              </div>
              <div class="col-md-8">
                : {{ thausandSparator(getDataVessel.load_capacity) }} MT
              </div>
            </div>
            <div
              v-else
              class="row"
            >
              <div class="col-md-4">
                Horsepower
              </div>
              <div class="col-md-8">
                : {{ thausandSparator(getDataVessel.engine.horsepower) }} HP
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="px-3">
        <div class="pricing-header  mx-auto text-center">
          <h2 class="mt-5">
            Choose License
          </h2>
          <p class="text-muted">
            Select your license plan to find the right cargo owner for you.
          </p>
        </div>
        <div class="card-deck text-center">
          <div
            v-for="(licenseItem, index) in licensePlan"
            :key="index"
            class="card rounded mb-4 shadow-sm"
          >
            <div class="card-header">
              <h4 class="my-0 font-weight-normal">
                {{ licenseItem.title }}
              </h4>
            </div>
            <div class="card-body">
              <h1 class="card-title pricing-card-title">
                {{ licenseItem.duration }}<small class="text-muted">{{ licenseItem.package }}</small>
              </h1>
              <ul class="list-unstyled mt-3 mb-4">
                {{ licenseItem.text }}
              </ul>
              <button
                type="button"
                class="btn btn-lg btn-block btn-primary"
                @click="selectLicense(licenseItem.count, licenseItem.periode)"
              >
                Get It Now
              </button>
            </div>
          </div>
        </div>
      </div>
      <footer class="pt-4 my-md-5 pt-md-5 border-top">
        <div class="row">
          <div class="col-12 col-md">
            <small class="d-block mb-3 text-muted">Zonasea &copy; 2020</small>
          </div>
        </div>
      </footer>
    </div>
    <LicenseAction
      :data="license"
      :show="showModal"
      @toggle="showModal = !showModal"
    />
    <Fab />
  </div>
</template>

<script>
import Fab from '@/components/Fab'
import LicenseAction from '@/components/modals/LicenseAction'
import formatChar from "@/mixins/formatChar"
export default {
  name: 'PaymentLicense',
  components: {
    Fab,
    LicenseAction
  },
  mixins: [formatChar],
  data () {
    return {
      showModal : false,
      license: null,
      licensePlan : [
        { title: 'Basic Sail', duration: '1', package:'month', text: 'Find cargo owner in 1 month quickly and easily', count: '1', periode: '0' },
        { title: 'Delight Sail', duration: '3', package:'months', text: 'Find cargo owner in 3 months quickly and easily', count: '3', periode: '0' },
        { title: 'Delight Sail', duration: '6', package:'months', text: 'Find cargo owner in 6 months quickly and easily', count: '6', periode: '0' },
        { title: 'Amazing Sail', duration: '1', package:'year', text: 'Find cargo owner in 1 year quickly and easily', count: '1', periode: '1 '}
      ]
    }
  },
  computed: {
    getDataVessel () {
      return this.$store.getters['moduleBuyLicense/getDataVessel']
    },
    isLoading () {
      return this.$store.getters['moduleBuyLicense/getLoading']
    }
  },
  created () {
    if (this.$route.params.id != 0) this.$store.dispatch('moduleBuyLicense/LOAD_DETAIL_VESSEL', this.$route.params.id)
  },
  methods: {
    selectLicense (count, periode) {
      this.showModal = true
      this.license = {
        vessel: this.$route.params.id != 0 ? this.getDataVessel : null,
        count: count,
        periode: periode
      }
    }
  },
}
</script>

<style scoped>
  .container {
    max-width: 1080px;
  }

  .pricing-header {
    max-width: 700px;
  }

  .card-deck .card {
    min-width: 200px;
  }

  .detail {
    max-width: 800px;
    margin: 0 auto;
  }
</style>
